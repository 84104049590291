import React from "react"
import ReactDOM from "react-dom"
import { MotionConfig, AnimationFeature, ExitFeature, GesturesFeature } from "framer-motion"
import "./index.css"
import App from "./App"
import { ThemeProvider } from "./Theme/ThemeContext"

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider>
			<MotionConfig features={[AnimationFeature, ExitFeature, GesturesFeature]}>
				<App />
			</MotionConfig>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
// 	serviceWorker.register()
// } else {
// 	serviceWorker.unregister()
// }
