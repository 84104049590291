/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Suspense, lazy, useState, useEffect } from "react"
import { Route, Switch, useLocation } from "react-router-dom"
import { Flex } from "util/commonComponents"
import { AnimatePresence } from "framer-motion"
const Posts = lazy(() => import("./Pages/Posts/Posts"))
const Post = lazy(() => import("./Pages/Post/Post"))
const AboutMe = lazy(() => import("./Pages/AboutMe/AboutMe"))
const Projects = lazy(() => import("./Pages/Project/Projects"))
const NormalSidebar = lazy(() => import("./Sidebar/Sidebar"))
const ScrollToTopButton = lazy(() => import("../util/scrollToTopButton"))
// Function that wraps the component in a React Suspense component. Provides a fallback Loading screen
const WaitingComponent = (Component: any, otherProps: any) => {
	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	return (props: any) => (
		<Suspense
			// Fallback UI displays a loading spinner while rendering
			fallback={
				<Flex style={{ justifyContent: "center" }}>
					<h2>Loading... </h2>
				</Flex>
			}
		>
			<Component {...otherProps} {...props} />
		</Suspense>
	)
}

const HomeContainer = (): JSX.Element => {
	const location = useLocation()
	const [firstPage, setFirstPage] = useState({ page: location.pathname, isFirstPage: true })
	useEffect(() => {
		// To work out whether or not animations should play. If first load (refresh or link) it plays animation
		// otherwise it wont have any
		if (location.pathname !== firstPage.page) {
			setFirstPage({ isFirstPage: false, page: location.pathname })
		}
	}, [location, firstPage.page])
	return (
		<React.Fragment>
			<Suspense
				// Fallback UI displays a loading spinner while rendering
				fallback={
					<Flex style={{ justifyContent: "center" }}>
						<h2>Loading... </h2>
					</Flex>
				}
			>
				<AnimatePresence exitBeforeEnter={true}>
					<NormalSidebar>
						<Switch location={location} key={location.pathname}>
							<Route exact path={`/posts/:postname`} render={WaitingComponent(Post, { firstPage })} />
							<Route exact path={`/`} render={WaitingComponent(AboutMe, { firstPage })} />
							<Route exact path={`/posts`} render={WaitingComponent(Posts, { firstPage })} />
							<Route exact path={`/aboutme`} render={WaitingComponent(AboutMe, { firstPage })} />
							<Route exact path={`/projects`} render={WaitingComponent(Projects, { firstPage })} />
							<Route path="*" render={() => <div>Page does not exist</div>} />
						</Switch>
						<ScrollToTopButton />
					</NormalSidebar>
				</AnimatePresence>
			</Suspense>
		</React.Fragment>
	)
}
export default HomeContainer
