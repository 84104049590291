import React from "react"
import HomeContainer from "./HomeContainer/HomeContainer"
import styled from "@emotion/styled"
import { useTheme } from "./Theme/ThemeContext"
import { m as motion } from "framer-motion"
import { Global, css } from "@emotion/core"
import { HashRouter as Router } from "react-router-dom"
import ServiceWokerNotification from "util/ServiceWorker"
const Wrapper = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	z-index: -100;
	width: 100%;
	height: 100%;
`

const WrapperVariants = {
	dark: {
		backgroundColor: "#36373a",
	},
	light: {
		backgroundColor: "#ffffff",
	},
}

const App = () => {
	const themeState = useTheme()
	return (
		<div style={{ maxHeight: "100%", height: "100%" }}>
			<ServiceWokerNotification />
			<Wrapper variants={WrapperVariants} animate={themeState.darkmode ? "dark" : "light"} />
			<Global
				styles={(theme) => css`
					body {
						color: ${theme.body};
						background-color: ${theme.background};
						margin: 0;
						padding: 0;
						min-height: 100%;
						max-width: 100%;
					}
				`}
			/>
			<Router>
				<HomeContainer />
			</Router>
		</div>
	)
}
export default App
