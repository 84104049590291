/** @jsx jsx */
import styled from "@emotion/styled"
import { jsx } from "@emotion/core"
import React from "react"
import { Link } from "react-router-dom"
import { css } from "@emotion/core"
// Declare the controls variable so we can access it from both functions

const breakpoints = [0, 500, 600, 768, 992, 1200]

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`)

export const Flex = styled.div`
	display: flex;
`
export const FlexWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export const H1 = styled.h1`
	margin-top: 0;
`
export const CustomGrid = styled.div`
	display: grid;
	grid-template-rows: auto;
	${mq[0]} {
		grid-template-columns: auto;
		grid-template-areas: "main";
	}
	${mq[1]} {
		grid-template-columns: auto;
		grid-template-areas: "main";
	}
	${mq[2]} {
		grid-template-columns: minmax(280px, 30%) 2% auto;
		grid-template-areas: "sidebar divider main";
	}
`
export const ButtonLink = styled.button`
	background: none !important;
	border: none;
	padding: 0 !important;
	text-decoration: underline;
	cursor: pointer;
	font-weight: bold;
	font-size: 1em;
	&:hover {
		color: ${(props: any) => props.theme.blue};
	}
`

export const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`
export const NavigationColumn = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 80px;
	${mq[0]} {
		align-items: center;
	}
	${mq[2]} {
		align-items: baseline;
	}
`

export const Bold = styled.span`
	font-weight: bold;
`
export const Italic = styled.span`
	font-style: italic;
`

export const StyledALink = styled.a`
	color: ${(props: any) => props.theme.body};
	font-weight: bold;
	&:hover {
		color: ${(props: any) => props.theme.blue} !important;
	}
`
export const StyledLink = styled(Link)`
	text-decoration: none;
	color: ${(props: any) => props.theme.blue};
	&:hover {
		text-decoration: underline;
	}
`
export const PageContainer = styled.div`
	${mq[0]} {
		padding: 1.875rem 1.25rem;
	}
	${mq[2]} {
		padding: 1.875rem 2.5rem 0 1.25rem;
	}
	margin-bottom: 40px;
`
export const icon = (theme: any): any => css`
	width: 36px;
	height: 36px;
	& path {
		fill: ${theme.white} !important;
	}
	&:hover {
		& path {
			fill: ${theme.lightBlue} !important;
		}
	}
`
