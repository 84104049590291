const white = "#FFFFFF"
const black = "#161617"
const gray = "#36373a"
const lightBlue = "#ADC9FF"
const darkBlue = "#0049DB"
// old blue  #5d93ff
// Light theme
const themeLight = {
	background: white,
	sidebarBackground: gray,
	body: black,
	cargBg: white,
	lighterBody: gray,
	white: white,
	blue: darkBlue,
	lightBlue: lightBlue,
	inverseBody: white,
	inverseBackground: gray,
}

// Dark theme
const themeDark = {
	background: gray,
	sidebarBackground: gray,
	body: white,
	cargBg: gray,
	lighterBody: "#dedede",
	white: white,
	blue: lightBlue,
	lightBlue: lightBlue,
	inverseBody: black,
	inverseBackground: white,
}
export type Theme = {
	background: string
	body: string
}

//Based on currently set mode return the dark or light theme
const theme = (mode: string): Theme => (mode === "darkmode" ? themeDark : themeLight)

export default theme
