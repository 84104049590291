/** @jsx jsx */
import { jsx } from "@emotion/core"
import React, { FC, useEffect } from "react"
import styled from "@emotion/styled"
import { m as motion, AnimatePresence } from "framer-motion"
import * as serviceWorker from "../serviceWorker"

const Snackbar = styled.div`
	min-width: 250px;
	margin-left: -125px;
	background-color: ${(props: any) => props.theme.inverseBackground};
	color: ${(props: any) => props.theme.inverseBody};
	text-align: center;
	border-radius: 2px;
	padding: 16px;
	position: fixed;
	z-index: 1;
	left: 50%;
	top: 30px;
	font-size: 1rem;
`

const notificationVariants = {
	show: {
		opacity: 1,
		transition: {
			delay: 0,
			duration: 0.5,
		},
	},
	hidden: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
}

const SnackbarWrapper = ({ showReload, reloadPage }: any) => {
	return (
		<AnimatePresence>
			<motion.div variants={notificationVariants} initial="hidden" animate="show" exit="hidden">
				{showReload && (
					<Snackbar>
						A new version is available!{" "}
						<button color="inherit" onClick={reloadPage}>
							Reload
						</button>
					</Snackbar>
				)}
			</motion.div>
		</AnimatePresence>
	)
}

const ServiceWorkerWrapper: FC = () => {
	const [showReload, setShowReload] = React.useState(false)
	const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null)

	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		console.log("New Update available")
		setShowReload(true)
		setWaitingWorker(registration.waiting)
	}

	useEffect(() => {
		if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
			console.log("Service Worker Enabled")
			serviceWorker.register({ onUpdate: onSWUpdate })
		} else {
			console.log("Service Worker Disabled")
			serviceWorker.unregister()
		}
	}, [])

	const reloadPage = () => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" })
		setShowReload(false)
		window.location.reload(true)
	}

	return <SnackbarWrapper showReload={showReload} reloadPage={reloadPage} />
}

export default ServiceWorkerWrapper
